import React, { FC, useEffect, useMemo, useState, Fragment } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { XIcon } from '@heroicons/react/solid'

import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, FilterIcon } from '@heroicons/react/solid'

import { getStrapiMedia } from 'services/strapi'
import {
  Button,
  Select,
  SelectOption,
  SearchCombobox,
  SelectCantonOption,
} from 'ui'
import Link from 'next/link'
import { Hero } from 'components/shared/Hero'
import { Brand, Prices, Canton } from 'core/entities'
import { PriceApiService } from 'services/priceApi'
import { useClassNames } from 'hooks'

const sortOptions = [
  {
    name: 'Niedrigster Preis',
    sortKey: 'price',
    sortDirection: 'asc',
  },
  { name: 'Höchster Preis', sortKey: 'price', sortDirection: 'desc' },
  {
    name: 'Niedrigste PS',
    sortKey: 'car.ps',
    sortDirection: 'asc',
  },
  { name: 'Höchste PS', sortKey: 'car.ps', sortDirection: 'desc' },
  {
    name: 'Niedrigste Kaution',
    sortKey: 'car.deposit',
    sortDirection: 'asc',
  },
  {
    name: 'Höchste Kaution',
    sortKey: 'car.deposit',
    sortDirection: 'desc',
  },
]

const hoursOptions = [
  { name: '3 Stunden', value: 3, checked: true },
  { name: '6 Stunden', value: 6, checked: false },
  { name: '12 Stunden', value: 12, checked: false },
  { name: '24 Stunden', value: 24, checked: false },
]

type Props = {
  prices: Prices[]
  brands: Brand[]
  cantons: Canton[]
}

export const HomeView: FC<Props> = ({ prices, brands, cantons }) => {
  const classNames = useClassNames()

  const [pricesList, setPricesList] = useState<Prices[]>(prices)
  const [hours, setHours] = useState<number>(3)
  const [brand, setBrand] = useState<SelectOption | null>()
  const [model, setModel] = useState<SelectOption | null>()
  const [canton, setCanton] = useState<SelectCantonOption[] | any>([])
  const [hasNoDeposit, setHasNoDeposit] = useState<boolean>(false)
  const [isUnlimited, setIsUnlimited] = useState<boolean>(true)
  const [sortAccordingTo, setSortAccordingTo] = useState<string>('')
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const router = useRouter()

  useEffect(() => {
    PriceApiService.getPrices(
      isUnlimited,
      hours,
      brand?.key,
      model?.key,
      hasNoDeposit,
      sortAccordingTo,
      canton
    ).then((filteredCars) => {
      setPricesList(filteredCars)
    })
  }, [isUnlimited, hours, brand, model, hasNoDeposit, sortAccordingTo, canton])

  const clearFilter = () => {
    setBrand(null)
    setModel(null)
    console.log('clearFilter')
  }

  const clearCantonFilter = () => {
    setCanton([])
  }

  const brandOptions = useMemo(
    () =>
      brands.map((item) => ({
        key: `${item.id}`,
        text: item?.name,
        imageUrl: getStrapiMedia(item?.imageUrl),
      })),
    [brands]
  )

  const modelOptions = useMemo(() => {
    const selectedBrand = brands.find(
      (element) => `${element.id}` === brand?.key
    )
    return (
      selectedBrand?.models.map((item) => ({
        key: `${item.id}`,
        text: item?.name,
      })) || []
    )
  }, [brand, brands])

  const cantonOptions = useMemo(
    () =>
      cantons
        .filter((item) => {
          return (
            canton.filter((element: any) => element?.name === item?.name)
              .length == 0
          )
        })
        .map((item) => ({
          key: `${item?.id}`,
          name: item?.name,
          imageUrl: getStrapiMedia(item?.imageUrl),
        })),
    [canton]
  )

  return (
    <div className="min-h-full p-4 sm:p-6 lg:p-8">
      <Hero />
      <div id="vergleichen">
        <div>
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative flex flex-col w-full h-full max-w-xs py-4 pb-12 ml-auto overflow-y-auto bg-white shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900">
                        Filter
                      </h2>
                      <button
                        type="button"
                        className="flex items-center justify-center w-10 h-10 p-2 -mr-2 text-gray-400 bg-white rounded-md"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Menü schliessen</span>
                        <XIcon className="w-6 h-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters */}
                    <form className="mt-4 border-t border-gray-200">
                      <h3 className="flow-root px-2 pt-3">
                        <span className="font-medium text-gray-900">
                          Marke & Modell
                        </span>
                      </h3>
                      <div className="flex flex-col gap-4 px-2 pb-3 mt-4">
                        <Select
                          label="Marke"
                          className="flex-1"
                          placeholder="Hier Marke wählen"
                          selected={brand}
                          options={brandOptions}
                          onChange={(val) => {
                            setBrand(val)
                            setModel(null)
                          }}
                        />
                        <Select
                          label="Model"
                          className="flex-1"
                          placeholder="Hier Model wählen"
                          selected={model}
                          options={modelOptions}
                          onChange={setModel}
                        />
                        {brand ? (
                          <Button
                            onClick={clearFilter}
                            className="inline-flex self-start items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                          >
                            <XIcon
                              className="-ml-0.5 mr-2 h-4 w-4"
                              aria-hidden="true"
                            />
                            Marke & Modell zurücksetzen
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>

                      <h3 className="flow-root px-2 pt-3 border-t border-gray-200">
                        <span className="font-medium text-gray-900">
                          Standort
                        </span>
                      </h3>
                      <div className="px-2 pb-3 mt-4">
                        <div className="flex flex-col gap-4 mt-4">
                          <SearchCombobox
                            label="Kanton"
                            placeholder="Nach Kanton suchen"
                            selected={canton}
                            options={cantonOptions}
                            onClick={(val) => {
                              setCanton((oldArray: SelectCantonOption[]) => [
                                ...oldArray,
                                val,
                              ])
                            }}
                            removeItem={(val) => {
                              setCanton((oldArray: SelectCantonOption[]) =>
                                oldArray.filter((item) => item !== val)
                              )
                            }}
                          />

                          {canton.length === 0 ? (
                            ''
                          ) : (
                            <Button
                              onClick={clearCantonFilter}
                              disabled={!canton.length}
                              className={`inline-flex self-start items-center px-3 py-2 border ${
                                canton.length === 0
                                  ? 'cursor-none border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                                  : 'cursor-pointer shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                              }`}
                            >
                              <XIcon
                                className="-ml-0.5 mr-2 h-4 w-4"
                                aria-hidden="true"
                              />
                              Standort zurücksetzen
                            </Button>
                          )}
                        </div>
                      </div>

                      <h3 className="flow-root px-2 pt-3 border-t border-gray-200">
                        <span className="font-medium text-gray-900">
                          Anzahl Stunden
                        </span>
                      </h3>

                      <div className="px-2 pb-3 mt-4">
                        <div className="space-y-4">
                          {hoursOptions.map((option) => {
                            return (
                              <div
                                className="flex items-center"
                                key={option?.value}
                              >
                                <input
                                  id={`hours-${option?.value}`}
                                  name={'hours'}
                                  defaultValue={'white'}
                                  type="checkbox"
                                  checked={option.value === hours}
                                  onChange={() => setHours(option?.value)}
                                  className="w-4 h-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
                                />
                                <label
                                  htmlFor={`hours-${option?.value}`}
                                  className="flex-1 min-w-0 ml-3 text-gray-500"
                                >
                                  {option?.name}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                      </div>

                      <h3 className="flow-root px-2 pt-3 border-t border-gray-200">
                        <span className="font-medium text-gray-900">
                          Weitere Filter
                        </span>
                      </h3>

                      <div className="px-2 pb-3 mt-4">
                        <div className="space-y-4">
                          <div className="flex items-center">
                            <input
                              id={'filter-car'}
                              name={'test'}
                              defaultValue={'white'}
                              type="checkbox"
                              defaultChecked={hasNoDeposit}
                              onChange={() => setHasNoDeposit(!hasNoDeposit)}
                              className="w-4 h-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
                            />
                            <label
                              htmlFor={'filter-car'}
                              className="flex-1 min-w-0 ml-3 text-gray-500"
                            >
                              Ohne Kaution
                            </label>
                          </div>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div className="max-w-7xl mx-auto">
            <div className="relative z-10 flex items-baseline justify-end pt-6 pb-6 border-b border-gray-200">
              <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex justify-center text-sm font-medium text-gray-700 group hover:text-gray-900">
                      Sortieren nach
                      <ChevronDownIcon
                        className="flex-shrink-0 w-5 h-5 ml-1 -mr-1 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 w-40 mt-2 origin-top-right bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option) => (
                          <Menu.Item key={option?.name}>
                            {() => (
                              <a
                                onClick={() => {
                                  setSortAccordingTo(
                                    `${option.sortKey}:${option.sortDirection}`
                                  )
                                }}
                                className={classNames(
                                  `${option.sortKey}:${option.sortDirection}` ===
                                    sortAccordingTo
                                    ? 'font-medium text-gray-900 bg-gray-100'
                                    : 'text-gray-500',
                                  'block px-4 py-2 text-sm cursor-pointer'
                                )}
                              >
                                {option?.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                <button
                  type="button"
                  className="fixed inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-white bg-primary-600 border border-transparent rounded-md shadow-sm lg:hidden hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 bottom-5 right-5"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  Filter
                  <FilterIcon
                    className="ml-2 -mr-0.5 h-4 w-4"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>

            <section aria-labelledby="products-heading" className="pt-6 pb-24">
              <h2 id="products-heading" className="sr-only">
                Filter
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10">
                {/* Filters */}
                <form className="hidden lg:block">
                  <div className="pt-2 pb-6 border-b border-gray-200">
                    <h3 className="flow-root">
                      <span className="font-medium text-gray-900">
                        Marke & Modell
                      </span>
                    </h3>
                    <div className="flex flex-col gap-4 mt-4">
                      <Select
                        label="Marke"
                        className="flex-1"
                        placeholder="Hier Marke wählen"
                        selected={brand}
                        options={brandOptions}
                        onChange={(val) => {
                          setBrand(val)
                          setModel(null)
                        }}
                      />
                      <Select
                        label="Model"
                        className="flex-1"
                        placeholder="Hier Model wählen"
                        selected={model}
                        options={modelOptions}
                        onChange={setModel}
                      />
                      {brand ? (
                        <Button
                          onClick={clearFilter}
                          disabled={!brand}
                          className={`inline-flex self-start items-center px-3 py-2 border ${
                            brand === undefined
                              ? 'cursor-none border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                              : 'cursor-pointer shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                          }`}
                        >
                          <XIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          Marke & Modell zurücksetzen
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>

                  <div className="pt-2 pb-6 border-b border-gray-200">
                    <h3 className="flow-root">
                      <span className="font-medium text-gray-900">
                        Standort
                      </span>
                    </h3>
                    <div className="flex flex-col gap-4 mt-4">
                      <SearchCombobox
                        label="Kanton"
                        placeholder="Nach Kanton suchen"
                        selected={canton}
                        options={cantonOptions}
                        onClick={(val) => {
                          setCanton((oldArray: SelectCantonOption[]) => [
                            ...oldArray,
                            val,
                          ])
                        }}
                        removeItem={(val) => {
                          setCanton((oldArray: SelectCantonOption[]) =>
                            oldArray.filter((item) => item !== val)
                          )
                        }}
                      />

                      {canton.length === 0 ? (
                        ''
                      ) : (
                        <Button
                          onClick={clearCantonFilter}
                          disabled={!canton.length}
                          className={`inline-flex self-start items-center px-3 py-2 border ${
                            canton.length === 0
                              ? 'cursor-none border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                              : 'cursor-pointer shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                          }`}
                        >
                          <XIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          Standort zurücksetzen
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="py-6 border-b border-gray-200">
                    <h3 className="flow-root">
                      <span className="font-medium text-gray-900">
                        Anzahl Stunden
                      </span>
                    </h3>
                    <div className="px-2 pb-3 mt-4">
                      <div className="space-y-4">
                        {hoursOptions.map((option) => {
                          return (
                            <div
                              className="flex items-center"
                              key={option.value}
                            >
                              <input
                                id={`hours-${option.value}`}
                                name={'hours'}
                                defaultValue={'white'}
                                type="checkbox"
                                checked={option.value === hours}
                                onChange={() => setHours(option.value)}
                                className="w-4 h-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
                              />
                              <label
                                htmlFor={`hours-${option.value}`}
                                className="flex-1 min-w-0 ml-3 text-gray-500"
                              >
                                {option?.name}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="py-6 border-b border-gray-200">
                    <h3 className="flow-root">
                      <span className="font-medium text-gray-900">
                        Weitere Filter
                      </span>
                    </h3>
                    <div className="flex flex-col gap-4 mt-4">
                      <div className="space-y-4">
                        <div className="flex items-center">
                          <input
                            id={'filter-car'}
                            name={'test'}
                            defaultValue={'white'}
                            type="checkbox"
                            defaultChecked={hasNoDeposit}
                            onChange={() => setHasNoDeposit(!hasNoDeposit)}
                            className="w-4 h-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
                          />
                          <label
                            htmlFor={'filter-car'}
                            className="ml-3 text-sm text-gray-600"
                          >
                            Ohne Kaution
                          </label>
                        </div>
                        <div className="flex items-center">
                          <input
                            id={'filter-car'}
                            name={'test'}
                            defaultValue={'white'}
                            type="checkbox"
                            defaultChecked={!isUnlimited}
                            onChange={() => setIsUnlimited(!isUnlimited)}
                            className="w-4 h-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
                          />
                          <label
                            htmlFor={'filter-car'}
                            className="ml-3 text-sm text-gray-600"
                          >
                            Nur beschränkte Kilometer Angebote anzeigen
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {/* Product grid */}
                <div className="lg:col-span-3">
                  <h2 className="text-3xl font-bold tracking-tight">
                    {isUnlimited ? 'Unlimitierte' : 'Beschränkte'} Kilometer
                    Angebote
                  </h2>

                  <ul className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2 md:grid-cols-2 sm:mt-6 ">
                    {pricesList.length === 0 ? (
                      <div className="p-4 rounded-md bg-yellow-50">
                        <div className="flex">
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-yellow-800">
                              Mit diesen Filtern konnten wir leider keine
                              Sportwagen finden.
                            </h3>
                            <div className="mt-2 text-sm text-yellow-700">
                              <p>Versuche es mit anderen Filtern.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      pricesList.map((price) => {
                        if (!price.car) return null
                        return (
                          <li
                            className="flex flex-col col-span-1 overflow-hidden bg-white divide-y divide-gray-200 rounded-lg shadow cursor-pointer hover:shadow-lg"
                            key={price.car?.id}
                          >
                            <Link href={`cars/${price.car?.id}`}>
                              <div className="relative flex items-center justify-center flex-shrink-0 w-full h-48 mx-auto">
                                {price.car?.images.length > 0 && (
                                  <Image
                                    className="object-cover"
                                    fill
                                    sizes='w-full h-48'
                                    src={getStrapiMedia(price.car?.images[0])}
                                    alt={price.car?.images[0].alternativeText || 'Sportwagen'}
                                  />
                                )}
                                {price.car?.images.length === 0 && (
                                  <span>Keine Bilder</span>
                                )}
                              </div>

                              <div className="flex flex-col flex-1 p-4">
                                <dl className="flex flex-col justify-between flex-grow ">
                                  <dt className="sr-only">Marke und Model</dt>
                                  <dd className="text-sm text-gray-800">
                                    {price?.car?.brand?.name}{' '}
                                    {price.car?.model?.name} (
                                    {new Date(
                                      price?.car?.firstTrafficDate
                                    ).getFullYear()}
                                    )
                                  </dd>
                                </dl>
                                <h3 className="text-xl font-bold">
                                  {price.price} CHF
                                </h3>
                                <dl className="flex flex-col justify-between flex-grow mt-1">
                                  <dt className="sr-only">Stundenansatz</dt>
                                  <dd className="text-sm text-gray-500">
                                    ab {price?.hours} Stunden
                                  </dd>
                                </dl>
                              </div>
                              <div className="flex -mt-px divide-x divide-gray-200">
                                <div className="relative flex items-center justify-around flex-grow px-2 py-1 border-transparent flex-column">
                                  <div className="relative hidden w-8 h-8">
                                    <Image
                                      src="/images/icon-tacho.png"
                                      alt="Pferdestärke"
                                      fill
                                      sizes='w-8 h-8'
                                    />
                                  </div>
                                  <div>
                                    <p className="text-gray-900 truncate text-medium">
                                      {price.car?.ps}
                                    </p>
                                    <p className="text-sm text-gray-500 font-sm">
                                      Pferdestärke
                                    </p>
                                  </div>
                                </div>
                                <div className="relative flex items-center justify-around flex-grow px-2 py-1 border-transparent flex-column">
                                  <div className="relative hidden w-8 h-8">
                                    <Image
                                      src="/images/icon-car-engine.png"
                                      alt="Hubraum"
                                      fill
                                      sizes='w-8 h-8'
                                    />
                                  </div>
                                  <div>
                                    <p className="text-gray-900 truncate text-medium">
                                      {price.car?.hub}
                                    </p>
                                    <p className="text-sm text-gray-500 font-sm">
                                      Hubraum in cm3
                                    </p>
                                  </div>
                                </div>
                                <div className="relative flex items-center justify-around flex-grow px-2 py-1 border-transparent flex-column">
                                  <div className="relative hidden w-8 h-8">
                                    <Image
                                      src="/images/icon-deposit.png"
                                      alt="Hubraum"
                                      fill
                                      sizes='w-8 h-8'
                                    />
                                  </div>
                                  <div>
                                    <p className="text-gray-900 truncate text-medium">
                                      {price.car?.deposit}
                                    </p>
                                    <p className="text-sm text-gray-500 font-sm">
                                      Kaution in CHF
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {price.car.rental_company && (
                                <div className="flex items-center p-4">
                                  <div className="w-8 h-8 relative">
                                    {price.car.rental_company.imageUrl && (
                                      <Image
                                        className="object-cover"
                                        fill
                                        sizes='w-8 h-8'
                                        src={getStrapiMedia(
                                          price.car.rental_company.imageUrl
                                        )}
                                        alt="User Image"
                                      />
                                    )}
                                  </div>
                                  <div className="ml-4">
                                    <p className="text-sm font-medium text-gray-500">
                                      {price.car.rental_company.name}
                                      <br />
                                      {price.car.rental_company.street}
                                      {price.car.rental_company.street && ', '}
                                      {price.car.rental_company.zip}{' '}
                                    </p>
                                  </div>
                                </div>
                              )}
                              <div>
                                <button
                                  type="button"
                                  onClick={() =>
                                    router.push({
                                      href: `/cars/${price?.car?.id}`,
                                    })
                                  }
                                  className="w-full px-4 py-3 text-base font-medium text-white border border-transparent rounded-md rounded-t-none shadow-sm bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                                >
                                  Ansehen
                                </button>
                              </div>
                            </Link>
                          </li>
                        )
                      })
                    )}
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}
