import React, { FC } from "react"

import { HomeView } from "components/views/HomeView"

import { PriceApiService } from "services/priceApi"
import { BrandApiService } from "services/brandApi"
import { Brand, Prices, Canton } from "core/entities"
import { CantonApiService } from "services/cantonApi"

type Props = {
  prices: Prices[]
  brands: Brand[]
  cantons: Canton[]
}

const HomePage: FC<Props> = (props) => {
  return <HomeView {...props} />
}

export const getServerSideProps = async () => {
  try {
    const [prices, brands, cantons] = await Promise.all([
      PriceApiService.getPrices(),
      BrandApiService.getBrands(),
      CantonApiService.getCantons(),
    ])
    return {
      props: {
        prices,
        brands,
        cantons,
      },
    }
  } catch (error) {
    return {
      props: {
        prices: [],
        brands: [],
        cantons: [],
      },
    }
  }
}

export default HomePage
