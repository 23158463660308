import qs from 'qs'

import { Canton, Prices } from 'core/entities'
import { StrapiArrayResponse } from 'core/strapi'

import api from './api'
import { parseStrapiArray } from './strapi'

const getPrices = async (
  isUnlimited = true,
  hours = 3,
  brand?: string,
  model?: string,
  hasNoDeposit?: boolean,
  sortAccordingTo?: string,
  location?: Canton[]
) => {
  const queryDraft: any = {
    filters: {},
    populate: 'deep',
  }
  const type = isUnlimited ? 'unlimited' : 'limited'
  queryDraft.filters.type = { $eq: type }
  queryDraft.filters.hours = { $eq: hours }
  queryDraft.filters.car = {};
  if (brand) {
    queryDraft.filters.car.brand = { id: { $eq: parseInt(brand) } }
  }
  if (model) {
    queryDraft.filters.car.model = { id: { $eq: parseInt(model) } }
  }
  if (hasNoDeposit) {
    queryDraft.filters.car.deposit = { $eq: 0 }
  }
  if (sortAccordingTo) queryDraft.sort = sortAccordingTo
  if (location?.length) {
    const locations = []
    for (let i = 0; i < location.length; i++) {
      locations.push({ name: { $eq: location[i]?.name } })
    }
    queryDraft.filters.car = {
      rental_company: {
        canton: {
          $or: [...locations],
        },
      },
      ...queryDraft.filters.car,
    }
  }

  const query = qs.stringify(queryDraft, { encodeValuesOnly: true })
  console.log(query)
  const response = await api.get<StrapiArrayResponse<Prices>>(
    `/prices?${query}`
  )
  const result = parseStrapiArray<Prices>(response.data)

  return result
}

export const PriceApiService = {
  getPrices,
}
